<template>
    <div>
        <div v-if="!loading">
            <v-card>
                <v-card-text>
                    <v-row>
                        <!-- <v-col cols="12" sm="3">
                            <v-subheader style="font-size: 16px">Active Shipments <v-spacer></v-spacer><v-text-field
                                    placeholder="Search" prepend-inner-icon="search" v-model="search" outlined dense
                                    hide-details clearable></v-text-field></v-subheader>
                            <v-divider></v-divider>
                           
                        </v-col> -->
                        <v-col cols="12">
                            <div style="height: 96vh">
                                <gmap-map :center="center" :zoom="zoom" style="width: 100%; height: 100%;"
                                    v-bind:options="mapStyle" class="mapLocal">
                                    <gmap-custom-marker v-for="shipment in positionVessels" :key="shipment.vesselId"
                                        @click="showByVesselId = shipment" alignment="center" :marker="shipment.position"
                                        :clickable="true">
                                        <v-icon @click="setVessel(shipment)"
                                            :class="selectedVessel && selectedVessel.vesselId == shipment.vesselId ? 'pulse-beacon' : ''"
                                            :color="shipment.color">fiber_manual_record</v-icon>


                                    </gmap-custom-marker>

                                    <gmap-info-window v-if="showByVesselId" :position="showByVesselId.position"
                                        :opened="showByVesselId != null">
                                        <v-card style="background-color: white !important; color: white !important"
                                            class="ma-0 pa-0">
                                            <v-card-title style="font-size: 16px; color: black">
                                                <v-avatar size="24" left class="mr-2"
                                                    v-if="showByVesselId.vessel.countryCode">
                                                    <v-img contain
                                                        :src="`https://cdn.loglive.io/flags/4x3/${showByVesselId.vessel.countryCode.toLowerCase()}.svg`"></v-img>
                                                </v-avatar>
                                                {{ showByVesselId.vessel.name }}
                                            </v-card-title>
                                        </v-card>
                                    </gmap-info-window>


                                    <gmap-polyline :key="actualRouteKey" v-if="selectedRoute"
                                        :path="selectedRoute.routeHistoryData" v-bind:options="{
                                            strokeColor: 'green',
                                            icons: [
                                                {
                                                    icon: {
                                                        path: 'M 0,0 1,3 -1,3 0,0 z',
                                                        strokeOpacity: 1,
                                                        scale: 2.5,
                                                    },
                                                    offset: '0',
                                                    repeat: '50px',
                                                },
                                            ],
                                        }"></gmap-polyline>

                                    <gmap-polyline :key="estimateRouteKey" v-if="selectedRoute"
                                        :path="selectedRoute.routeEstimateData" v-bind:options="{
                                            strokeColor: '#607e87',
                                            strokeOpacity: 0,
                                            icons: [{
                                                icon: {
                                                    path: 'M 0,-1 0,1',
                                                    strokeOpacity: 1,
                                                    scale: 3
                                                },
                                                offset: '0',
                                                repeat: '20px'
                                            }],
                                        }"></gmap-polyline>

                                    <gmap-custom-marker :offsetY="-18" :key="originKey"
                                        v-if="selectedRoute && selectedRoute.startLocode && selectedRoute.startLat && selectedRoute.startLng"
                                        alignment="center" :marker="{
                                            lng: originLocation.lng,
                                            lat: originLocation.lat,
                                        }">
                                        <div
                                            style="border-radius: 50%; background-color: var(--v-primary-base) !important;border:  3px solid var(--v-primary-base)">
                                            <v-avatar size="32">
                                                <v-img contain v-if="selectedRoute.startLocode" class="pa-0"
                                                    :src="`https://cdn.loglive.io/flags/4x3/${selectedRoute.startLocode.substring(0, 2).toLowerCase()}.svg`"></v-img>
                                            </v-avatar>
                                            <div class="map-marker"></div>
                                        </div>

                                    </gmap-custom-marker>

                                    <gmap-custom-marker :offsetY="-18" :key="destinationKey"
                                        v-if="selectedRoute && selectedRoute.endLocode && selectedRoute.endLat && selectedRoute.endLng"
                                        alignment="center" :marker="{
                                            lng: destinationLocation.lng,
                                            lat: destinationLocation.lat,
                                        }">
                                        <div
                                            style="border-radius: 50%; background-color: var(--v-primary-base) !important;border:  3px solid var(--v-primary-base)">
                                            <v-avatar size="32">
                                                <v-img contain v-if="selectedRoute.endLocode" class="pa-0"
                                                    :src="`https://cdn.loglive.io/flags/4x3/${selectedRoute.endLocode.substring(0, 2).toLowerCase()}.svg`"></v-img>
                                            </v-avatar>
                                            <div class="map-marker"></div>
                                        </div>

                                    </gmap-custom-marker>

                                    <!-- <gmap-info-window v-if="marker" :key="markerKey" :options="{
                                        maxWidth: 300,
                                    }" :opened="showMarker" :position="marker.marker">
                                        <v-card style="background: white" class="grey--text pa-0 ma-0">
                                            <v-card-text class="grey--text pa-0 ma-0">
                                                <b style="font-size: 16px">{{ marker.vessel.name }}</b>
                                                <br />
                                                <span style="font-size: 14px">{{ marker.code }}</span>
                                            </v-card-text>
                                        </v-card>
                                    </gmap-info-window> -->
                                </gmap-map>
                                <v-expand-transition>

                                    <v-card key="poiKey"
                                        style="background-color: var(--v-background-base)!important;border-radius: 20px; "
                                        v-if="selectedRoute != null && viewInfo" class="voyageCard">
                                        <v-card-title class="text-left">
                                            <v-row justify="start" align="center">
                                                <v-avatar size="24" left class="mr-1">
                                                    <v-img contain
                                                        :src="`https://cdn.loglive.io/flags/4x3/${selectedRoute.startLocode.substring(0, 2).toLowerCase()}.svg`"></v-img>
                                                </v-avatar> {{ selectedRoute.startLocode }}
                                                <v-icon small class="mx-1">arrow_forward</v-icon>
                                                <v-avatar size="24" class="ml-1 mr-1">
                                                    <v-img contain
                                                        :src="`https://cdn.loglive.io/flags/4x3/${selectedRoute.endLocode.substring(0, 2).toLowerCase()}.svg`"></v-img>
                                                </v-avatar>
                                                {{ selectedRoute.endLocode }}
                                            </v-row>
                                        </v-card-title>
                                        <v-card-subtitle class="text-left my-0 py-0" v-if="selectedRoute.vesselVoyageLegs">
                                            {{ selectedRoute.vesselVoyageLegs.length }} Legs
                                        </v-card-subtitle>
                                        <v-card-text style="max-height: 40vh; overflow-y: auto" class=" ml-0 my-0 py-0">
                                            <v-list dense class="mx-0 px-0">
                                                <v-progress-linear v-if="loadingVoyageLeg" indeterminate
                                                    color="primary"></v-progress-linear>
                                                <v-list-item v-for="leg in selectedRoute.vesselVoyageLegs" :key="leg.id"
                                                    class="my-0 py-0 pb-1" style="border-top: 0.5px solid grey">
                                                    <v-list-item-action class="ml-0 pl-0">
                                                        <v-chip small>{{ leg.leg }}</v-chip>
                                                    </v-list-item-action>
                                                    <v-list-item-content class="text-left my-0 py-0">
                                                        <v-list-item-title>
                                                            <v-row justify="start" align="center"
                                                                class=" pt-1 mt-1 ml-1 mb-1 pb-1">
                                                                <v-avatar size="24" left class="mr-1">
                                                                    <v-img contain
                                                                        :src="`https://cdn.loglive.io/flags/4x3/${leg.startLocode.substring(0, 2).toLowerCase()}.svg`"></v-img>
                                                                </v-avatar> {{ leg.startLocode }}
                                                                <v-icon small class="mx-1">arrow_forward</v-icon>
                                                                <v-avatar size="24" class="ml-1 mr-1">
                                                                    <v-img contain
                                                                        :src="`https://cdn.loglive.io/flags/4x3/${leg.endLocode.substring(0, 2).toLowerCase()}.svg`"></v-img>
                                                                </v-avatar>
                                                                {{ leg.endLocode }}
                                                            </v-row>
                                                        </v-list-item-title>
                                                        <v-list-item-subtitle v-if="leg.etd"
                                                            :style="{ color: leg.isATD ? 'green' : 'var(--v-primaryText-base)' }">
                                                            {{ !leg.isATD ? 'ETD: ' : 'ATD: ' }} {{ formatDate(leg.etd) }}
                                                        </v-list-item-subtitle>
                                                        <v-list-item-subtitle v-if="leg.eta"
                                                            :style="{ color: leg.isATA ? 'green' : 'var(--v-primaryText-base)' }">
                                                            {{ !leg.isATA ? 'ETA: ' : 'ATA: ' }} {{ formatDate(leg.eta) }}
                                                        </v-list-item-subtitle>
                                                        <v-list-item-subtitle
                                                            v-if="leg.modeOfTransport == 'VESSEL' && leg.vessel">
                                                            <v-icon small class="mr-1">directions_boat</v-icon> {{
                                                                leg.vessel.name }}
                                                        </v-list-item-subtitle>
                                                        <v-list-item-subtitle v-else-if="leg.modeOfTransport == 'RAIL'">
                                                            <v-icon small class="mr-1">train</v-icon> via Rail
                                                        </v-list-item-subtitle>
                                                    </v-list-item-content>

                                                </v-list-item>
                                            </v-list>
                                        </v-card-text>
                                    </v-card>

                                </v-expand-transition>
                                <transition name="slide-info">
                                    <v-card key="poiKey" style="background-color: var(--v-background-base)!important"
                                        v-if="viewInfo" class="informationCard">
                                        <v-card-title v-if="!selectedVessel || searchVessel">
                                            <v-text-field autofocus placeholder="Search" prepend-inner-icon="search"
                                                v-model="search" outlined dense hide-details clearable></v-text-field>
                                        </v-card-title>
                                        <v-card-title v-else-if="selectedVessel && !searchVessel">
                                            <v-btn icon @click="searchVessel = true"><v-icon>arrow_back</v-icon></v-btn>
                                            <v-avatar size="24" left class="mr-2" v-if="selectedVessel.vessel.countryCode">
                                                <v-img contain
                                                    :src="`https://cdn.loglive.io/flags/4x3/${selectedVessel.vessel.countryCode.toLowerCase()}.svg`"></v-img>
                                            </v-avatar> {{ selectedVessel.vessel.name }}
                                        </v-card-title>

                                        <v-card-subtitle v-if="selectedVessel && !searchVessel">
                                            {{ selectedVessel.vessel.type }}
                                        </v-card-subtitle>
                                        <v-card-text class="mx-0 px-0" v-if="selectedVessel && !searchVessel">
                                            <v-divider></v-divider>
                                            <v-tabs style="background-color: transparent" grow v-model="tab">
                                                <v-tab style="background: transparent" href="#routes">
                                                    <v-icon color="secondary" class="mr-2">route</v-icon>
                                                    Routes</v-tab>
                                                <v-tab style="background: transparent" href="#products">
                                                    <v-icon color="secondary" class="mr-2">category</v-icon>
                                                    Products</v-tab>
                                            </v-tabs>
                                            <!-- <v-subheader style="font-size: 16px"> <v-icon color="secondary"
                                                    class="mr-2">route</v-icon> Routes</v-subheader> -->
                                            <v-tabs-items v-model="tab">
                                                <v-tab-item value="routes">
                                                    <v-card flat color="transparent">
                                                        <v-card-text class="ma-0 pa-0">
                                                            <v-list dense class="mx-0 px-0"
                                                                style="max-height: 65vh; overflow-y: auto">
                                                                <v-card outlined v-for="(voyage) in selectedVessel.voyages"
                                                                    :key="voyage.id"
                                                                    :style="{ 'background-color': 'transparent', 'border-left': selectedRoute && selectedRoute.id == voyage.id ? '4px solid var(--v-primary-base)' : '4px solid transparent' }"
                                                                    @click="selectRoute(voyage)">

                                                                    <v-card-text class="text-left">
                                                                        <div class="mt-3">
                                                                            <v-row justify="start" align="center"
                                                                                class="ml-1 mb-1 pb-1">
                                                                                <v-avatar size="24" left class="mr-1">
                                                                                    <v-img contain
                                                                                        :src="`https://cdn.loglive.io/flags/4x3/${voyage.startLocode.substring(0, 2).toLowerCase()}.svg`"></v-img>
                                                                                </v-avatar> {{ voyage.startLocode }}
                                                                                <v-icon small
                                                                                    class="mx-1">arrow_forward</v-icon>
                                                                                <v-avatar size="24" class="ml-1 mr-1">
                                                                                    <v-img contain
                                                                                        :src="`https://cdn.loglive.io/flags/4x3/${voyage.endLocode.substring(0, 2).toLowerCase()}.svg`"></v-img>
                                                                                </v-avatar>
                                                                                {{ voyage.endLocode }}
                                                                            </v-row>
                                                                        </div>
                                                                        <div>
                                                                            <v-row style="padding-left: 5px">
                                                                                <v-col cols="12"
                                                                                    v-if="voyage.originalVoyage">
                                                                                    Voyage No: {{ voyage.originalVoyage }}
                                                                                </v-col>
                                                                            </v-row>
                                                                        </div>
                                                                        <!-- <br> -->
                                                                        <div class="ml-1">
                                                                            <span>
                                                                                {{ formatDate(voyage.etd) }} - {{
                                                                                    formatDate(voyage.eta) }}
                                                                            </span> <br />
                                                                            <span>{{ calculateDays(new Date(), voyage.eta)
                                                                            }} Days remaining</span>
                                                                        </div>
                                                                    </v-card-text>
                                                                </v-card>

                                                            </v-list>

                                                        </v-card-text>
                                                    </v-card>

                                                </v-tab-item>
                                                <v-tab-item value="products">
                                                    <v-card flat color="transparent">
                                                        <v-card-text class="my-0 py-0 mx-0">
                                                            <v-progress-linear v-if="loadingProducts" color="primary"
                                                                indeterminate></v-progress-linear>
                                                            <v-expand-transition>
                                                                <v-list dense v-if="!loadingProducts"
                                                                    style="max-height: 65vh; overflow-y: auto">
                                                                    <v-list dense
                                                                        v-for="(voyage, index) in selectedVessel.voyageData.voyageInfo"
                                                                        :key="index">
                                                                        <v-divider></v-divider>
                                                                        <v-list-item>
                                                                            <v-list-item-content>
                                                                                <v-row justify="start" align="center"
                                                                                    class="ml-2 px-1">
                                                                                    <v-avatar size="24" left class="mr-1">
                                                                                        <v-img contain
                                                                                            :src="`https://cdn.loglive.io/flags/4x3/${getVoyageInfo(voyage.vesselVoyageId).startLocode.substring(0, 2).toLowerCase()}.svg`"></v-img>
                                                                                    </v-avatar> {{
                                                                                        getVoyageInfo(voyage.vesselVoyageId).startLocode
                                                                                    }}
                                                                                    <v-icon small
                                                                                        class="mx-1">arrow_forward</v-icon>
                                                                                    <v-avatar size="24" class="ml-1 mr-1">
                                                                                        <v-img contain
                                                                                            :src="`https://cdn.loglive.io/flags/4x3/${getVoyageInfo(voyage.vesselVoyageId).endLocode.substring(0, 2).toLowerCase()}.svg`"></v-img>
                                                                                    </v-avatar>
                                                                                    {{
                                                                                        getVoyageInfo(voyage.vesselVoyageId).endLocode
                                                                                    }}
                                                                                </v-row>
                                                                            </v-list-item-content>
                                                                        </v-list-item>
                                                                        <v-divider></v-divider>

                                                                        <!-- <v-subheader>

                                                                           
                                                                                
                                                                            </v-row>
                                                                        </v-subheader> -->
                                                                        <v-list-item v-for="product in voyage.products"
                                                                            :key="product">
                                                                            <v-list-item-content class="text-left">
                                                                                <v-list-item-title>
                                                                                    <v-icon small class="mr-2"
                                                                                        color="secondary">category</v-icon>
                                                                                    {{ productName(product) }}
                                                                                </v-list-item-title>
                                                                                <v-list-item-subtitle>
                                                                                    {{ containerCount(voyage.vesselVoyageId,
                                                                                        product) }} Container(s)
                                                                                </v-list-item-subtitle>
                                                                                <!-- <v-list-item-subtitle>
                                                                                    {{ palletCount(voyage.vesselVoyageId,
                                                                                        product) }} Pallet(s)
                                                                                </v-list-item-subtitle> -->
                                                                            </v-list-item-content>
                                                                        </v-list-item>
                                                                        <!-- <v-divider></v-divider> -->

                                                                    </v-list>
                                                                </v-list>

                                                            </v-expand-transition>
                                                        </v-card-text>
                                                    </v-card>
                                                </v-tab-item>
                                            </v-tabs-items>
                                        </v-card-text>
                                        <v-expand-transition>
                                            <v-card-text v-if="!selectedVessel || searchVessel">
                                                <v-list dense style="overflow-y: auto; height: 70vh">
                                                    <!-- <v-list dense subheader> -->
                                                        <v-list-item  v-for="shipment in filterShipments"
                                                        :key="shipment.vesselId" style="border-bottom: 1px solid grey" @click="setVessel(shipment)" :style="{'border-left': `3px solid ${shipment.color}`}" class="my-0 py-0">
                                                            <v-list-item-action>
                                                                <!-- <v-icon :color="shipment.color">fiber_manual_record</v-icon> -->
                                                                <v-avatar size="24" left class="mr-2" v-if="shipment.vessel.countryCode">
                            <v-img contain
                                :src="`https://cdn.loglive.io/flags/4x3/${shipment.vessel.countryCode.toLowerCase()}.svg`"></v-img>
                        </v-avatar> 
                                                            </v-list-item-action>
                                                            <v-list-item-content class="text-left">
                                                                <v-list-item-title>
                                                                    {{ shipment.vessel.name }}
                                                                </v-list-item-title>
                                                                <!-- <v-list-item-subtitle v-if="shipment.vessel.owner">
                                                                    Owner: {{ shipment.vessel.owner }}
                                                                </v-list-item-subtitle>
                                                                <v-list-item-subtitle v-if="shipment.vessel.manager">
                                                                    Manager: {{ shipment.vessel.manager }}
                                                                </v-list-item-subtitle> -->
                                                                <v-list-item-subtitle v-if="shipment.vessel.navigationStatus">
                                                                    {{ shipment.vessel.navigationStatus }}
                                                                </v-list-item-subtitle>
                                                                <v-list-item-subtitle>
                                                                    {{ shipment.voyages.length }} Route(s)
                                                                </v-list-item-subtitle>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    <!-- </v-list> -->
                                                </v-list>
                                            </v-card-text>
                                        </v-expand-transition>

                                    </v-card>
                                </transition>
                            </div>


                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-dialog v-model="viewMobileInfo" fullscreen>
                <v-card key="poiKey" style="background-color: var(--v-background-base)!important" class="ma-0 pa-0">
                    <v-card-title v-if="!selectedVessel || searchVessel">
                        <v-toolbar flat color="transparent">
                            <v-text-field autofocus placeholder="Search" prepend-inner-icon="search" v-model="search"
                                outlined dense hide-details clearable></v-text-field>
                            <v-spacer></v-spacer>
                            <v-btn text @click="viewMobileInfo = false">X</v-btn>
                        </v-toolbar>

                    </v-card-title>
                    <v-card-title v-else-if="selectedVessel && !searchVessel">
                        <v-btn icon @click="searchVessel = true"><v-icon>arrow_back</v-icon></v-btn>
                        <v-avatar size="24" left class="mr-2" v-if="selectedVessel.vessel.countryCode">
                            <v-img contain
                                :src="`https://cdn.loglive.io/flags/4x3/${selectedVessel.vessel.countryCode.toLowerCase()}.svg`"></v-img>
                        </v-avatar> {{ selectedVessel.vessel.name }}
                    </v-card-title>

                    <v-card-subtitle v-if="selectedVessel && !searchVessel">
                        {{ selectedVessel.vessel.type }}
                    </v-card-subtitle>
                    <v-card-text class="mx-0 px-0" v-if="selectedVessel && !searchVessel">
                        <v-divider></v-divider>
                        <v-tabs style="background-color: transparent" grow v-model="tab">
                            <v-tab style="background: transparent" href="#routes">
                                <v-icon color="secondary" class="mr-2">route</v-icon>
                                Routes</v-tab>
                            <v-tab style="background: transparent" href="#products">
                                <v-icon color="secondary" class="mr-2">category</v-icon>
                                Products</v-tab>
                        </v-tabs>
                        <!-- <v-subheader style="font-size: 16px"> <v-icon color="secondary"
                                                    class="mr-2">route</v-icon> Routes</v-subheader> -->
                        <v-tabs-items v-model="tab">
                            <v-tab-item value="routes">
                                <v-card flat color="transparent">
                                    <v-card-text class="ma-0 pa-0">
                                        <v-list dense class="mx-0 px-0" style="max-height: 85vh; overflow-y: auto">
                                            <v-card outlined v-for="(voyage) in selectedVessel.voyages" :key="voyage.id"
                                                :style="{ 'background-color': 'transparent', 'border-left': selectedRoute && selectedRoute.id == voyage.id ? '4px solid var(--v-primary-base)' : '4px solid transparent' }"
                                                @click="selectRoute(voyage)">

                                                <v-card-text class="text-left">
                                                    <div class="mt-3">
                                                        <v-row justify="start" align="center" class="ml-1 mb-1 pb-1">
                                                            <v-avatar size="24" left class="mr-1">
                                                                <v-img contain
                                                                    :src="`https://cdn.loglive.io/flags/4x3/${voyage.startLocode.substring(0, 2).toLowerCase()}.svg`"></v-img>
                                                            </v-avatar> {{ voyage.startLocode }}
                                                            <v-icon small class="mx-1">arrow_forward</v-icon>
                                                            <v-avatar size="24" class="ml-1 mr-1">
                                                                <v-img contain
                                                                    :src="`https://cdn.loglive.io/flags/4x3/${voyage.endLocode.substring(0, 2).toLowerCase()}.svg`"></v-img>
                                                            </v-avatar>
                                                            {{ voyage.endLocode }}
                                                        </v-row>
                                                    </div>
                                                    <div>
                                                        <v-row style="padding-left: 5px">
                                                            <v-col cols="12" v-if="voyage.originalVoyage">
                                                                Voyage No: {{ voyage.originalVoyage }}
                                                            </v-col>
                                                        </v-row>
                                                    </div>
                                                    <!-- <br> -->
                                                    <div class="ml-1">
                                                        <span>
                                                            {{ formatDate(voyage.etd) }} - {{
                                                                formatDate(voyage.eta) }}
                                                        </span> <br />
                                                        <span>{{ calculateDays(new Date(), voyage.eta)
                                                        }} Days remaining</span>
                                                    </div>
                                                </v-card-text>
                                            </v-card>

                                        </v-list>

                                    </v-card-text>
                                </v-card>

                            </v-tab-item>
                            <v-tab-item value="products">
                                <v-card flat color="transparent">
                                    <v-card-text class="my-0 py-0">
                                        <v-progress-linear v-if="loadingProducts" color="primary"
                                            indeterminate></v-progress-linear>
                                        <v-expand-transition>
                                            <v-list dense v-if="!loadingProducts" class="mx-0 px-0"
                                                style="max-height: 85vh; overflow-y: auto">
                                                <v-list dense
                                                    v-for="(voyage, index) in selectedVessel.voyageData.voyageInfo"
                                                    :key="index">
                                                    <v-subheader>
                                                        <v-row justify="start" align="center" class="ml-1 mb-1 pb-1">
                                                            <v-avatar size="24" left class="mr-1">
                                                                <v-img contain
                                                                    :src="`https://cdn.loglive.io/flags/4x3/${getVoyageInfo(voyage.vesselVoyageId).startLocode.substring(0, 2).toLowerCase()}.svg`"></v-img>
                                                            </v-avatar> {{
                                                                getVoyageInfo(voyage.vesselVoyageId).startLocode
                                                            }}
                                                            <v-icon small class="mx-1">arrow_forward</v-icon>
                                                            <v-avatar size="24" class="ml-1 mr-1">
                                                                <v-img contain
                                                                    :src="`https://cdn.loglive.io/flags/4x3/${getVoyageInfo(voyage.vesselVoyageId).endLocode.substring(0, 2).toLowerCase()}.svg`"></v-img>
                                                            </v-avatar>
                                                            {{
                                                                getVoyageInfo(voyage.vesselVoyageId).endLocode
                                                            }}
                                                        </v-row>
                                                    </v-subheader>
                                                    <v-list-item v-for="product in voyage.products" :key="product">
                                                        <v-list-item-content class="text-left">
                                                            <v-list-item-title>
                                                                <v-icon small class="mr-2"
                                                                    color="secondary">category</v-icon>
                                                                {{ productName(product) }}
                                                            </v-list-item-title>
                                                            <v-list-item-subtitle>
                                                                {{ containerCount(voyage.vesselVoyageId,
                                                                    product) }} Container(s)
                                                            </v-list-item-subtitle>
                                                            <v-list-item-subtitle>
                                                                {{ palletCount(voyage.vesselVoyageId,
                                                                    product) }} Pallet(s)
                                                            </v-list-item-subtitle>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                    <v-divider></v-divider>

                                                </v-list>
                                            </v-list>

                                        </v-expand-transition>
                                    </v-card-text>
                                </v-card>
                            </v-tab-item>
                        </v-tabs-items>
                    </v-card-text>
                    <v-expand-transition>
                        <v-card-text v-if="!selectedVessel || searchVessel">
                            <v-list dense style="overflow-y: auto; height: 85vh">
                                <v-list dense subheader v-for="shipment in filterShipments" :key="shipment.vesselId"
                                    style="border-bottom: 1px solid grey">
                                    <v-list-item @click="setVessel(shipment)">
                                        <v-list-item-action>
                                            <v-icon :color="shipment.color">fiber_manual_record</v-icon>
                                        </v-list-item-action>
                                        <v-list-item-content class="text-left">
                                            <v-list-item-title>
                                                {{ shipment.vessel.name }}
                                            </v-list-item-title>
                                            <v-list-item-subtitle>
                                                {{ shipment.voyages.length }} Route(s)
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-list>
                        </v-card-text>
                    </v-expand-transition>

                </v-card>
            </v-dialog>
            <!-- <div v-else style="height: 90vh; padding-top: 0; margin-top: 0" class="text-center">
                <PageComingSoon />
            </div> -->
        </div>
        <div v-else style="
                height: 80vh;
                padding-top: 0;
                margin-top: 0;
                background: transparent;
              " class="text-center">
            <valhalla-loading :height="'85vh'" />
        </div>

    </div>
</template>
<script>
import axios from 'axios'
import dateFormat from "dateformat";
import distinctColors from 'distinct-colors'
import GmapCustomMarker from "vue2-gmap-custom-marker";
import * as moment from "moment";
// import PageComingSoon from '@/components/SystemComponents/PageComingSoon';

export default {
    components: {
        GmapCustomMarker,
        // PageComingSoon
    },
    data: () => ({
        // test: [[{"lat":-33.92,"lng":18.42},{"lat":-35,"lng":18},{"lat":-14.7307,"lng":1.7833},{"lat":-0.00002,"lng":-10},{"lat":5.5302,"lng":-13.4277},{"lat":11,"lng":-17},{"lat":15,"lng":-18},{"lat":18.1072,"lng":-18},{"lat":21,"lng":-18},{"lat":24.657,"lng":-16.6896},{"lat":28.3,"lng":-15.3},{"lat":28.1,"lng":-15.42}],[{"lat":28.1,"lng":-15.42},{"lat":28.3,"lng":-15.3},{"lat":33,"lng":-17},{"lat":35.807,"lng":-13.4272},{"lat":38.5,"lng":-9.6},{"lat":38.3559,"lng":-9.284},{"lat":37.9542,"lng":-8.9215},{"lat":37.95,"lng":-8.87}]],
        // test2: [[{"lat":-33.92,"lng":18.42},{"lat":-34.082,"lng":18.357000000000003},{"lat":-34.838,"lng":18.063},{"lat":-31.959605,"lng":15.567495},{"lat":-17.771095000000003,"lng":4.215805},{"lat":-12.521098,"lng":0.01580500000000007},{"lat":-2.209622,"lng":-8.232505},{"lat":0.829513,"lng":-10.514154999999999},{"lat":4.700666999999999,"lng":-12.913545},{"lat":6.350669999999999,"lng":-13.963545},{"lat":10.17953,"lng":-16.464154999999998},{"lat":11.6,"lng":-17.15},{"lat":14.4,"lng":-17.849999999999998},{"lat":15.46608,"lng":-18},{"lat":17.64112,"lng":-18},{"lat":18.54112,"lng":-18},{"lat":20.56608,"lng":-18},{"lat":21.54855,"lng":-17.80344},{"lat":24.108449999999998,"lng":-16.88616},{"lat":25.20345,"lng":-16.48116},{"lat":27.75355,"lng":-15.50844},{"lat":28.27,"lng":-15.318000000000001},{"lat":28.130000000000003,"lng":-15.402},{"lat":28.1,"lng":-15.42}],[{"lat":28.1,"lng":-15.42},{"lat":28.130000000000003,"lng":-15.402},{"lat":28.27,"lng":-15.318000000000001},{"lat":29.005,"lng":-15.555},{"lat":32.295,"lng":-16.744999999999997},{"lat":33.42105,"lng":-16.46408},{"lat":35.38595,"lng":-13.96312},{"lat":36.210950000000004,"lng":-12.853119999999999},{"lat":38.096050000000005,"lng":-10.17408},{"lat":38.478385,"lng":-9.5526},{"lat":38.377514999999995,"lng":-9.3314},{"lat":38.29564499999999,"lng":-9.229625},{"lat":38.014455,"lng":-8.975875},{"lat":37.95357,"lng":-8.913775},{"lat":37.950630000000004,"lng":-8.877725},{"lat":37.95,"lng":-8.87}]],
        actualRouteKey: 7000,
        center: { lat: 0.00, lng: 0.00 },
        estimateRouteKey: 8000,
        loading: true,
        originKey: 5000,
        destinationKey: 6000,
        loadingProducts: false,
        loadingVoyageLeg: false,
        legColors: [
            '#cba54c',
            '#95b28a',
            '#a93573',
            '#cc0f29',
            '#acd71e'
        ],
        legKey: 9000,
        mapStyle: {
            scrollwheel: true,
            zoomControl: false,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
            styles: [
                { elementType: "geometry", stylers: [{ color: "#283a54" }] },
                { elementType: "labels.text.stroke", stylers: [{ color: "#283a54" }] },
                { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
                {
                    featureType: "administrative.locality",
                    elementType: "labels.text.fill",
                    stylers: [{ color: "#d59563", visibility: "off", }],
                },
                {
                    featureType: "administrative",
                    elementType: "geometry",
                    stylers: [
                        {
                            visibility: "off",
                        },
                    ],
                },
                {
                    featureType: "poi",
                    elementType: "labels.text.fill",
                    stylers: [{ color: "#d59563", visibility: "off", }],
                },
                {
                    featureType: "poi.park",
                    elementType: "geometry",
                    stylers: [{ color: "#263c3f", visibility: "off", }],
                },
                {
                    featureType: "poi.park",
                    elementType: "labels.text.fill",
                    stylers: [{ color: "#6b9a76", visibility: "off", }],
                },
                {
                    featureType: "road",
                    elementType: "geometry",
                    stylers: [{ color: "#38414e", visibility: "off", }],
                },
                {
                    featureType: "road",
                    elementType: "geometry.stroke",
                    stylers: [{ color: "#212a37", visibility: "off", }],
                },
                {
                    featureType: "road",
                    elementType: "labels.text.fill",
                    stylers: [{ color: "#9ca5b3", visibility: "off", }],
                },
                {
                    featureType: "road.highway",
                    elementType: "geometry",
                    stylers: [{ color: "#746855", visibility: "off", }],
                },
                {
                    featureType: "road.highway",
                    elementType: "geometry.stroke",
                    stylers: [{ color: "#1f2835", visibility: "off", }],
                },
                {
                    featureType: "road.highway",
                    elementType: "labels.text.fill",
                    stylers: [{ color: "#f3d19c", visibility: "off", }],
                },
                {
                    featureType: "transit",
                    elementType: "geometry",
                    stylers: [{ color: "#2f3948" }],
                },
                {
                    featureType: "transit.station",
                    elementType: "labels.text.fill",
                    stylers: [{ color: "#d59563" }],
                },
                {
                    featureType: "water",
                    elementType: "geometry",
                    stylers: [{ color: "#212121" }],
                },
                {
                    featureType: "water",
                    elementType: "labels.text.fill",
                    stylers: [{ color: "#515c6d" }],
                },
                {
                    featureType: "water",
                    elementType: "labels.text.stroke",
                    stylers: [{ color: "#17263c" }],
                },
            ],
        },
        search: null,
        searchVessel: false,
        selectKey: 3000,
        selectedVessel: null,
        selectedRoute: null,
        shipmentExistingQuery: undefined,
        shipmentTimeout: undefined,
        shipments: [],
        showByVesselId: null,
        tab: 'shipments',
        viewInfo: true,
        viewMobileInfo: false,
        zoom: 2
    }),
    computed: {
        actualRoute() {
            try {
                let result = []
                if (this.selectedRoute && this.selectedRoute.routeHistoryData) {
                    result = this.selectedRoute.routeHistoryData.map(x => ({
                        lat: x.lat,
                        lng: x.lng
                    }))
                }
                console.log('History constructed data', result)
                return Array.isArray(result) ?? []
            } catch (e) {
                return []
            }

        },
        estimatedRoute() {
            try {
                let result = []
                if (this.selectedRoute && this.selectedRoute.routeEstimateData && this.selectedRoute.routeEstimateData.Path && this.selectedRoute.routeEstimateData.Path.Points.length > 0) {
                    result = this.selectedRoute.routeEstimateData.Path.Points.map(x => ({
                        lat: x[1],
                        lng: x[0]
                    }))
                }
                return Array.isArray(result) ?? []
            } catch (e) {
                return []
            }
        },
        filterShipments() {
            let result = this.shipments
            if (this.search) {
                result = this.shipments.filter(x => x.vessel.name.toLowerCase().includes(this.search.toLowerCase()))
            }
            return result
        },
        originLocation() {
            let result = null
            if (this.selectedRoute && this.selectedRoute.startLat && this.selectedRoute.startLng) {
                result = {
                    lat: this.selectedRoute.startLat,
                    lng: this.selectedRoute.startLng
                }
            }
            console.log('Origin', result)
            return result
        },
        destinationLocation() {
            let result = null
            if (this.selectedRoute && this.selectedRoute.endLat && this.selectedRoute.endLng) {
                result = {
                    lat: this.selectedRoute.endLat,
                    lng: this.selectedRoute.endLng
                }
            }
            console.log('Destination', result)

            return result
        },
        positionVessels() {
            let result = []
            if (this.shipments && this.shipments.length > 0) {
                result = this.shipments.filter(shipment => shipment.vessel.latitude != null && shipment.vessel.longitude != null)
                result = result.map(x => ({ ...x, position: { lat: parseFloat(x.vessel.latitude), lng: parseFloat(x.vessel.longitude) } }))
            }
            return result
        },

    },
    watch: {
        '$store.state.token': {
            immediate: true,
            handler() {
                //     if (this.loading && this.shipmentExistingQuery) {
                //     this.shipmentExistingQuery.abort()
                // }
                this.getActiveShipments()
            }
        }
    },
    // beforeMount(){

    // },
    created() {
        this.viewInfo = !this.$vuetify.breakpoint.mobile
        this.viewMobileInfo = this.$vuetify.breakpoint.mobile
        this.getActiveShipments()
    },
    // beforeUpdate(){
    //     this.$API.abortRequests()
    // },
    methods: {
        calculateDays(start, end) {
            start = moment(start)
            end = moment(end)
            let difference = end.diff(start, 'days')
            let count = difference
            return count
        },
        formatDate(date) {
            let result = null;
            if (date) {
                result = dateFormat(new Date(date), "dd-mm-yyyy");
            }
            return result;
        },
        async getActiveShipments() {
            this.loading = true
            let data = await this.$API.getActiveShipments()
            var palette = distinctColors({ count: data.length, lightMin: 30 })
            let colors = palette.map(x => x.hex())
            data = data.map((x, i) => ({ ...x, color: colors[i] }))
            this.shipments = data
            this.loading = false;
        },
        async getVesselProducts(vessel) {
            this.loadingProducts = true
            vessel.voyageData = await this.$API.getVesselProducts(vessel.vesselId)
            this.loadingProducts = false
        },
        getVoyageInfo(vesselVoyageId) {
            let find = this.selectedVessel.voyages.find(x => x.id == vesselVoyageId)
            return find
        },
        products(shipment) {
            let uniqueProducts = [...new Set(shipment.products.map(x => x.productId))]
            let products = uniqueProducts.map(x => shipment.products.find(y => y.productId == x))
            products.sort((a, b) => (a.product.name > b.product.name) ? 1 : ((b.product.name > a.product.name) ? -1 : 0))
            return products
        },
        containerCount(voyageId, productId) {
            let voyage = this.selectedVessel.voyageData.voyageInfo.find(x => x.vesselVoyageId == voyageId)
            let count = voyage.productSummary.filter(x => x.productId == productId).length
            return count
        },
        palletCount(voyageId, productId) {
            let voyage = this.selectedVessel.voyageData.voyageInfo.find(x => x.vesselVoyageId == voyageId)
            let count = voyage.productSummary.filter(x => x.productId == productId).map(x => x.count).reduce((a, b) => a + b, 0)
            return count
        },
        productName(id) {
            let product = this.selectedVessel.voyageData.products.find(x => x.id == id)
            return product ? product.name : null
        },
        async getVoyageLegs(route) {
            this.loadingVoyageLeg = true
            route.vesselVoyageLegs = await this.$API.getVoyageLegs(route.id)
            for (let i = 0; i < route.vesselVoyageLegs.length; i++) {
                if (route.vesselVoyageLegs[i].estimateRoute) {
                    let data = await axios.get(route.vesselVoyageLegs[i].estimateRoute)
                    if (data && data.data) {
                        route.vesselVoyageLegs[i].estimateRoute = data.data
                    } else {
                        route.vesselVoyageLegs[i].estimateRoute = []
                    }
                }


            }
            this.loadingVoyageLeg = false
            this.legKey++
        },
        routeLegs() {
            let result = []
            if (this.selectedRoute && this.selectedRoute.vesselVoyageLegs) {
                let legs = this.selectedRoute.vesselVoyageLegs
                console.log(legs)
                for (let i = 0; i < legs.length; i++) {
                    if (legs[i].estimateRoute) {
                        result.push(legs[i].estimateRoute)
                    } else if (legs[i].startLat && legs[i].startLng && legs[i].endLat && legs[i].endLng) {
                        result.push([
                            { lat: legs[i].startLat, lng: legs[i].startLng },
                            { lat: legs[i].endLat, lng: legs[i].endLng }
                        ])
                    }
                }
            }
            return result
        },
        async selectRoute(route) {
            if (route.routeHistory && !route.routeHistoryData) {
                let data = await axios.get(route.routeHistory)
                if (data.data && Array.isArray(data.data)) {
                    data.data = data.data.map((x) => ({
                        lat: x.LATITUDE,
                        lng: x.LONGITUDE,
                        timestamp: x.TIMESTAMP,
                        speed: x.SPEED,
                        course: x.COURSE
                    }))
                    route.routeHistoryData = data.data
                    this.$nextTick(() => {
                        this.actualRouteKey++
                    })
                } else {
                    route.routeHistoryData = []
                }
            } else if (!route.routeHistoryData) {
                route.routeHistoryData = []
            }
            if (route.routeEstimate && !route.routeEstimateData) {
                let data = await axios.get(route.routeEstimate)
                if (data.data && data.data.Path && data.data.Path.Points.length > 0) {
                    route.routeEstimateData = data.data.Path.Points.map(x => ({
                        lat: x[1],
                        lng: x[0]
                    }))

                } else {
                    route.routeEstimateData = []
                }
            } else if (!route.routeEstimateData) {
                route.routeEstimateData = []
            }

            this.$nextTick(() => {
                this.actualRouteKey++
            })
            this.$nextTick(() => {
                this.estimateRouteKey++
            })
            if (route.routeDetail && !route.routeDetailData) {
                let data = await axios.get(route.routeDetail)
                if (data && data.data) {
                    route.routeDetailData = data.data
                } else {
                    route.routeDetailData = []
                }
            } else {
                route.routeDetailData = []
            }


            if (!route.vesselVoyageLegs) {
                this.getVoyageLegs(route)
            }
            this.selectedRoute = route
            this.selectKey++
            this.originKey++
            this.destinationKey++
            this.center = {
                lat: 0, lng: 0
            }
            this.zoom = 3
        },
        setVessel(vessel) {
            this.tab = 'shipments'
            if (vessel.vessel.latitude && vessel.vessel.longitude) {
                vessel.position = { lat: parseFloat(vessel.vessel.latitude), lng: parseFloat(vessel.vessel.longitude) }
                this.center = vessel.position
            }
            this.selectedRoute = null
            this.selectKey++
            this.selectedVessel = vessel
            this.getVesselProducts(vessel)
            this.searchVessel = false
            this.viewInfo = !this.$vuetify.breakpoint.mobile
            this.viewMobileInfo = this.$vuetify.breakpoint.mobile
            this.zoom = 3
        }
    }
}
</script>
<style scoped>
.pulse-beacon {
    animation: pulse-beacon 2s infinite;
    z-index: 10;
}

@keyframes pulse-beacon {
    0% {
        border-radius: 50%;
        box-shadow: 0 0 0 0px rgb(135, 135, 135);
    }

    100% {
        border-radius: 50%;
        box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
    }
}

.slide-info-leave-active {
    transition: 0.5s;
}

.slide-info-enter-active {
    transition: 0.8s;
}

.slide-info-enter {
    transform: translateX(100%);
}

.slide-info-leave-to {
    transform: translateX(100%);
}

.voyageCard {
    position: absolute;
    overflow-y: scroll;
    border-radius: 16px;
    width: 19vw;
    bottom: 6vh;
    left: 0.5vw;
    z-index: 2000 !important;
}

.informationCard {
    position: absolute;
    overflow-y: scroll;
    border-radius: 16px;
    height: 80vh;
    width: 20vw;
    top: 6vh;
    right: 0.5vw;
    z-index: 2000 !important;
}

.map-marker {
    /* position: relative; */
}

.map-marker::after {
    position: absolute;
    content: '';
    width: 0px;
    height: 0px;
    /* top: -5px; */
    bottom: -20px;
    left: 10px;
    border: 10px solid transparent;
    border-top: 17px solid var(--v-primary-base);
}
</style>